export default function useServerAsyncData<T>(
  key: string,
  cb: () => Promise<T>,
  onError?: (e: Error) => void
) {
  onMounted(async () => {
    const asyncData = await asyncDataPromise;
    if (
      asyncData.data.value === 'undefined' &&
      (!asyncData.error || !(asyncData.error instanceof Error))
    ) {
      asyncData.execute().catch((error: Error) => {
        nextTick(() => {
          if (onError) {
            onError(error);
          }
        });
      });
    }
  });

  const asyncDataPromise = useAsyncData(
    key,
    () => cb().then(result => result || ('null' as const)),
    {
      default: () => 'undefined' as const
    }
  );

  if (process.server) {
    asyncDataPromise.then(asyncData => asyncData.execute());
  }

  return asyncDataPromise.then(asyncData => {
    if (
      asyncData.error.value &&
      asyncData.error.value instanceof Error &&
      onError
    ) {
      onError(asyncData.error.value);
    }

    return {
      ...asyncData,
      data: computed(() => {
        return asyncData.data.value === 'null'
          ? null
          : asyncData.data.value === 'undefined'
          ? undefined
          : (asyncData.data.value as T);
      })
    };
  });
}
